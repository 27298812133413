import React from 'react'
import { useEffect } from 'react'
import { navigate } from 'gatsby'

export default function PolicyAndTermEn(props) {
  useEffect(() => {
    const { hash } = window.location
    //   temp disable auto scoll
    //   window.requestAnimationFrame(() => {
    //     const anchor = document.querySelector(hash)
    //     const offset = anchor.getBoundingClientRect().top + window.scrollY

    //     window.scroll({ top: offset, left: 0 })
    //   })
    if (!hash) {
      navigate('/th/term-of-use')
    } else {
      if (hash == '#policy') {
        navigate('/th/privacy')
      } else {
        navigate('/')
      }
    }
  }, [])
  return (<div className="h-324"></div>)
  // return (
  //   <div className="fade-in" lang={`${props.lang}`}>
  //     <section id="term"></section>
  //     <div className="h-24 max:h-56 2xl:h-48 xl:h-40 lg:h-36 md:h-36 sm:h-28"></div>
  //     <div className="container-mobile mx-auto">
  //       <section id="term"></section>
  //       <div className="container-mobile mx-auto">
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-20 max:text-size-45 2xl:text-size-40 xl:text-size-35 lg:text-size-30 md:text-size-30 sm:text-size-25
  //                   "
  //         >
  //           Terms of Service
  //         </div>
  //         <br />
  //         Last updated 04/03/2021
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           TERMS OF SERVICES of www.aappoint.me
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           All terms and conditions in this document are applicable for your
  //           use of the website (or on any application on your mobile device) of
  //           Appointment Anywhere Co., Ltd. (the “Company”), which includes
  //           www.aappoint.me (the “Website”).
  //           <br />
  //           <br />
  //           Using the Website (or application on your mobile phone) constitutes
  //           your acknowledgment and acceptance of all of these terms of the
  //           Services. If you do not accept to be legally bound by the terms of
  //           any or all of the conditions stated herein, please do not use the
  //           Website (or application on your mobile phone).
  //           <br />
  //           <br />
  //           The Company reserves the sole right to amend or change the terms of
  //           use, policy, or manual of the Website at the discretion of the
  //           Company. Any amendment will be effective immediately upon the
  //           announcement of the revised version of the Website and any right
  //           that may exist within the acknowledgement notice about editing
  //           changes shall be waived. Therefore, if you do not agree to such
  //           changes, please stop using the Website.
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <br />
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Registration and the User Account
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           The Company reserves the right to only Services to the User who
  //           registers to create an account on the Company's the Website. Such
  //           User will be able to purchase the items, and use the Services and
  //           activities that are provided on the Website, such as ordering
  //           products or through auction from the Websites, etc. When you create
  //           an account, you agree to the following:
  //           <br />
  //           <br />
  //           <ul className="list-disc">
  //             <li>One User can register with one account only;</li>
  //             <li>
  //               You agree to provide detailed information that is required to be
  //               accurate and current;
  //             </li>
  //             <li>
  //               You are not authorized to share your username and password or
  //               other account access information with any other party
  //               temporarily or permanently;
  //             </li>
  //             <li>
  //               You agree to notify the Company immediately of any unauthorized
  //               use of your username or password;
  //             </li>
  //             <li>
  //               You are responsible for any actions arising through the use of
  //               your account and risks that may occur in every case, arising
  //               from access to your account without permission:
  //               <ul className="list-disc">
  //                 <li>
  //                   For online members who are under 20 years, any transactions
  //                   relating to finance, such as online purchases, or auctions,
  //                   you should carefully review the terms of use under the
  //                   guidance of your parents or legal guardian, and ensure that
  //                   the parents or guardian understand the terms and conditions
  //                   of the Website.
  //                 </li>
  //                 <li>
  //                   The Company reserves the right, at its sole discretion, to
  //                   revoke your membership immediately without prior notice, if
  //                   the Company finds you have committed any of the following
  //                   actions:
  //                   <ul className="list-square">
  //                     <li>
  //                       You provide false information on registration, including
  //                       not updating data information to be current.
  //                     </li>
  //                     <li>
  //                       Any action through your account, which is a breach of
  //                       the terms of use under the Terms of the Services and
  //                       other conditions.
  //                     </li>
  //                     <li>
  //                       Any actions that are committed through your account
  //                       without abiding by the law or public morality or are
  //                       carried out fraudulently.
  //                     </li>
  //                   </ul>
  //                   <li>
  //                     The Company shall publish a document on the terms of use
  //                     and disclosure of information about the registration and
  //                     use of the Website based policy on personal information,
  //                     which includes but is not limited to the disclosure of the
  //                     Organizer (as defined below). You can consider such a
  //                     document for more details.
  //                   </li>
  //                 </li>
  //               </ul>
  //             </li>
  //           </ul>
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Limitations of Use
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           In the use of this Website, you will proceed under the laws and
  //           regulations concerned, and agree to act as follows:
  //           <br />
  //           <ul className="list-disc">
  //             <li>Do not publish messages that harms a person's reputation;</li>
  //             <li>
  //               Do not publish unlawful, threatening, harassing, libelous,
  //               vulgar, obscene, pornographic, blasphemous, and cynical content;
  //             </li>
  //             <li>Do not incite rebellion;</li>
  //             <li>No commercial use without permission from the Company;</li>
  //             <li>
  //               Do not restrict anyone else from the Website without the
  //               permission of the Company;
  //             </li>
  //             <li>
  //               Do not publish links to the Website or any other content that is
  //               unlawful or inappropriate;
  //             </li>
  //             <li>
  //               Do not publish spam, direct marketing communication, advertising
  //               media, advertising sales support or persuasion without
  //               permission, or trading in other styles; and
  //             </li>
  //             <li>
  //               Do not publish viruses, spyware, Trojans or any other program to
  //               hide malicious flies, causing damage to the Website or the User.
  //             </li>
  //           </ul>
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Terms of Purchase
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           You acknowledge and manifestly agree that the Website is intended
  //           provide you with a quick, easy, and efficient Services to access
  //           items, including goods, services, tickets, and e-commerce
  //           activities. The Company is not the organizer for selling goods and
  //           services or the operator of any show or such goods whatsoever.
  //           Therefore, when you purchase any product through this Website, you
  //           have agreed to the following terms:
  //         </div>
  //         {/* =============================================================== */}
  //         <br />
  //         <ul className="list-disc">
  //           <li>
  //             Purchasing
  //             <ul className="list-disc">
  //               <li>
  //                 The Company and this Website are intermediaries in the sale of
  //                 goods or services, which include goods, services, and tickets
  //                 of shows from the organizers (the “Organizer”) or events and
  //                 provide the payment system for the Organizer only. Your
  //                 purchasing order through this Website is for the product from
  //                 the Organizer;
  //               </li>
  //               <li>
  //                 When the order is confirmed, this means that the transactions
  //                 conducted through the Website have been completed, which
  //                 refers to the fact that you have paid for the goods or
  //                 services including the fees relating to the Company. The
  //                 Company reserves the right to refund or cancel the
  //                 transaction, including in the case that the User was to make a
  //                 mistaken transaction. Although the Company confirms
  //                 transactions through various channels, such as the Websites or
  //                 by e-mail etc., the Company shall be deemed that the
  //                 transaction will be effective upon confirmation via the
  //                 Website;
  //               </li>
  //               <li>
  //                 The Company is not involved in pricing in all cases. The
  //                 Organizer will determine the price of goods or services,
  //                 discounts, or promotions (if applicable); and
  //               </li>
  //               <li>
  //                 The Company is not involved in determining the amount of goods
  //                 to be sold through the Website in all cases. In addition, in
  //                 some cases, the Organizer may arrange to distribute the goods
  //                 or services through various channels including the Websites,
  //                 so the Company cannot confirm the number of items that can be
  //                 sold through the Website. The amount of the goods or services
  //                 on the Website is defined by the Organizer only.
  //               </li>
  //             </ul>
  //           </li>
  //           <li>
  //             Payment Method
  //             <ul className="list-disc">
  //               <li>
  //                 You can make payment via the methods posted on the Company’s
  //                 the Website; and
  //               </li>
  //               <li>
  //                 The payment is completed, provided that the payment has been
  //                 confirmed via the Website.
  //               </li>
  //             </ul>
  //           </li>
  //           <li>
  //             The Services fees
  //             <ul className="list-disc">
  //               <li>
  //                 The User agrees that when it places an order of any type on
  //                 the Company's the Website, the Company shall have the right to
  //                 charge a the Services fee and other related charges for the
  //                 Services rendered according to the activity, in which the fees
  //                 are subject to change depending on the Services type; and
  //               </li>
  //               <li>
  //                 The Services fees are non-refundable under any conditions.
  //               </li>
  //             </ul>
  //           </li>
  //           <li>
  //             Postponement and cancellation of activity
  //             <ul className="list-disc">
  //               <li>
  //                 The User agrees that there may potentially be postponement or
  //                 cancellation of the activity, in which such postponement or
  //                 cancellation shall be decided by the Organizer or shop owner.
  //                 The Company will not take part in any consideration of the
  //                 decision process whatsoever; and
  //               </li>
  //               <li>
  //                 Should there be postponement or cancellation of the activity,
  //                 goods, or services, please directly follow up with the news
  //                 and/or notification from the Organizer or shop owner. The
  //                 Company may convey information regarding postponement or
  //                 cancellation of the event, goods, or services via the
  //                 Company’s the Website.
  //               </li>
  //             </ul>
  //           </li>
  //           <li>
  //             Refund eligibility
  //             <ul className="list-disc">
  //               <li>
  //                 In the case of postponement or cancellation of any activity,
  //                 and if you are eligible for a refund, you agree to contact the
  //                 Organizer or shop owner to be only party who is responsible
  //                 for your refund, except a case that the Company deems as a
  //                 special case whereby you shall be eligible for the refund from
  //                 that website’s event only. For more information, please
  //                 contact us via email customerservice@aappoint.me or contact
  //                 information below; and
  //               </li>
  //               <li>
  //                 The Company shall not be liable for any damages caused by
  //                 postponement or cancellation of the activity by the organizer
  //                 or shop owner in any conditions.
  //               </li>
  //             </ul>
  //           </li>
  //           <li>
  //             Responsibility for the final product’s content or characteristics
  //             <ul className="list-disc">
  //               <li>
  //                 The Company shall not take part in any aspect of the
  //                 organization of any activity. Should you find that the content
  //                 or characteristics of the product or activity do not meet with
  //                 the purpose of your purchase, you agree to directly inform the
  //                 Organizer and that you forego and/or withhold any request you
  //                 may have with the Company.
  //               </li>
  //             </ul>
  //           </li>
  //         </ul>
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Limitation of the Company’s Liability
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           <ul className="list-disc">
  //             <li>
  //               You acknowledge and manifestly agree that the Company is not
  //               responsible for any mistakes in the content (i.e. messages,
  //               photos, trademarks, information, and /or any other aspects) on
  //               the Company’s the Website that may result from out-of-date
  //               information or spelling mistakes arising from human errors of
  //               the staff of the Company or details acquired from the Organizer.
  //               The Company reserves the right to amend the content on the
  //               Website without prior notice.
  //             </li>
  //             <li>
  //               The Company shall not be held responsible for the content and
  //               information on the Website, www.aappoint.me, or for the
  //               accuracy, completeness, or benefits of the Company, which may be
  //               caused by a third party, or any User, including opinions and
  //               recommendations of the staff under the care of the Company.
  //             </li>
  //             <li>
  //               You acknowledge and manifestly agree that the Company admits no
  //               responsibility toward any the User’s inability to use the
  //               Services (in any aspect) of the Website.
  //             </li>
  //             <li>
  //               The media and content on the Company’s the Website may be
  //               inaccurate due to a technical fault or misspelling. Furthermore,
  //               providing media, information, and the Services on the Website
  //               will be in a “as is” manner with no condition, guarantee, or any
  //               type of disclaimer. Therefore, the Company refuses to guarantee
  //               the conditions of any product, whether explicitly or implicitly,
  //               in its entirety. This includes, but is not limited to, implied
  //               warranties or conditions suitable for commercial purposes by
  //               default, satisfactory quality, suitability of the purpose or the
  //               need to use for specific purpose, rightful ownership, and not
  //               infringing with respect to the Website and its content and media
  //               on the Website. The use of the Website shall not cause
  //               disruption and the use of the Website is subject to the laws
  //               imposed on you or the sending of your personal information which
  //               pertains to the Website shall be successful, correct, and
  //               delivered safely. Hence, any rejections mentioned above are
  //               subject to the extent permitted by law. agree to be responsible
  //               for any risk that may arise from choosing the device to use the
  //               Services from the Website such as mobile phones, computer,
  //               hardware, and other items including fees for the Services
  //               rendered.
  //             </li>
  //             <li>
  //               The media and content on the Company’s the Website may be
  //               inaccurate due to a technical fault or misspelling. Furthermore,
  //               providing media, information, and the Services on the Website
  //               will be in a “as is” manner with no condition, guarantee, or any
  //               type of disclaimer. Therefore, the Company refuses to guarantee
  //               the conditions of any product, whether explicitly or implicitly,
  //               in its entirety. This includes, but is not limited to, implied
  //               warranties or conditions suitable for commercial purposes by
  //               default, satisfactory quality, suitability of the purpose or the
  //               need to use for specific purpose, rightful ownership, and not
  //               infringing with respect to the Website and its content and media
  //               on the Website. The use of the Website shall not cause
  //               disruption and the use of the Website is subject to the laws
  //               imposed on you or the sending of your personal information which
  //               pertains to the Website shall be successful, correct, and
  //               delivered safely. Hence, any rejections mentioned above are
  //               subject to the extent permitted by law
  //             </li>
  //             <li>
  //               As far as the applicable law permits, the Company and its
  //               officers, directors, shareholders, employees, or representatives
  //               of the Company shall not be liable for direct, indirect,
  //               punitive or consequential damages, or any other damages of any
  //               kind. This includes, but is not limited to, loss of revenue,
  //               profits, goodwill, data, contracts, use of money, losses or
  //               damages caused by or relating in any way to business
  //               interruption, whether in tort of (including, but not limited to,
  //               negligence) contract or otherwise, as a result of, or relating
  //               to the use of, or inability to use the Website, its content or
  //               materials on the Website or accessed through the Website,
  //               including, but not limited to, any damages as a consequence of,
  //               or in relation to the use of reliable information received from
  //               the Website, or from the Organizers, or that which results from
  //               mistakes, omissions, interruptions, deletion of files or emails,
  //               errors, defects, viruses, delays in operation or transmission,
  //               or the inability to work on anything whether as a result of an
  //               event of force majeure, failure in communication, theft,
  //               destruction or unauthorized access of the Company’s internal
  //               records, programs, and/or the Services.
  //             </li>
  //           </ul>
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Liability Agreement
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           You agree to protect, indemnify, and defend the Company and its
  //           executives, directors, employees and representatives of the Company
  //           from the demands of compensation, debts, liabilities and expenses of
  //           any kind (including, but not limited to, reasonable attorney’s fees)
  //           that result from, or are relating to
  //           <br />
  //           <br />
  //           (1) your usage of the Company’s the Website
  //           <br />
  //           (2) breach of the Company’s terms and conditions
  //           <br />
  //           (3) breach of the rights of other users or
  //           <br />
  //           (4) any action committed by you in relation to the Company’s the
  //           Website.
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Rules on Intellectual Property Rights
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           <ul className="list-disc">
  //             <li>
  //               All content displayed on the Website shall be subject to the law
  //               on Intellectual Property whereby the Company or third parties
  //               allow companies to use their Intellectual Property. The content
  //               is to be used on the Website only. The Company prohibits any
  //               duplication of the said content for other purposes.
  //             </li>
  //             <li>
  //               The Company reserves all Intellectual Property Rights explicitly
  //               and originally to www.aappoint.me in terms of content, symbols,
  //               fonts, graphics, design, and other related content and
  //               information. By using this Website, you agree not to duplicate,
  //               download, distribute, or disseminate any part of the Website
  //               other than for personal usage. You shall not imitate any part of
  //               this the Website, in any form, unless you have received written
  //               permission to do so from the Company. The Company is not
  //               responsible for any mistakes in content caused by breach of
  //               Intellectual Property Rights or damage arising from the User, no
  //               matter what the circumstances may be.
  //             </li>
  //             <li>
  //               It is prohibited for the User to upload content in order make
  //               changes to the data or any part of the Website, www.aappoint.me,
  //               without written permission from the Company. Violators are
  //               liable for such action. Furthermore, information distributed on
  //               the Website, www.aappoint.me, shall belong to the Company
  //               indefinitely and cannot be claimed with reasons, exceptions, or
  //               raise any legal clauses to justify your claim. The Company can
  //               do as it pleases with that information or media. The User agrees
  //               that the Company holds the right to modify, adapt, reproduce,
  //               publish, distribute data and information the Services on the
  //               Website www.www.aappoint.me.me which the User owns.
  //             </li>
  //           </ul>
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Connecting to the Website
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           <ul className="list-disc">
  //             <li>
  //               You acknowledge and manifestly agree that in the case of any
  //               implementation of links from other websites to the Website
  //               www.aappoint.me, whether for commercial purposes or not, the
  //               Company shall not be affiliated with such links and shall not be
  //               responsible for any mistakes or damages which may arise from the
  //               use of the Website via third-party links, no matter what the
  //               circumstances may be.
  //             </li>
  //             <li>
  //               The Website, www.aappoint.me, contains links to other websites
  //               which are not under the supervision of the Company. Therefore,
  //               the Company waives any responsibility for any consequences
  //               arising from or activity on those sites, both directly and
  //               indirectly.
  //             </li>
  //           </ul>
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Suspension, Modification, Amendment of the Website
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           The Company reserves the right to modify, temporarily or permanently
  //           discontinue the Website, including parts of the Website, without
  //           prior notification. You agree and accept that the Company shall not
  //           be liable for any loss or damage arising from any suspension,
  //           modification, or termination of the Services or any part of the
  //           Website.
  //         </div>
  //         {/* =============================================================== */}
  //         <div className="h-6"></div>
  //         <div
  //           className="
  //                       text-body-header font-bold
  //                       text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                   "
  //         >
  //           Severability
  //         </div>
  //         <div className="h-4"></div>
  //         <div
  //           className="text-body-content-gray
  //                       text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                       leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                   "
  //         >
  //           <br />
  //           If any clause in these terms and conditions is deemed unlawful,
  //           void, or unenforceable for any particular reason, such clause shall
  //           be considered separate from these Terms of Services and this shall
  //           not affect the integrity and the effectiveness of the rest of these
  //           Terms of Services.
  //         </div>
  //       </div>
  //       <a id="policy"></a>
  //       <div className="h-32"></div>
  //       <div>
  //         <div className="container-mobile mx-auto">
  //           <div
  //             className="
  //                               text-body-header font-bold
  //                               text-size-20 max:text-size-45 2xl:text-size-40 xl:text-size-35 lg:text-size-30 md:text-size-30 sm:text-size-25
  //                       "
  //           >
  //             Privacy Policy
  //           </div>
  //           <br />
  //           <div className="h-6"></div>
  //           <div
  //             className="
  //                           text-body-header font-bold
  //                           text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                       "
  //           >
  //             Introduction
  //           </div>
  //           <div className="h-4"></div>
  //           <div
  //             className="text-body-content-gray
  //                           text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                           leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                       "
  //           >
  //             <br />
  //             Appointment Anywhere Company Limited ("Company", "Appointment
  //             Anywhere") and www.aappoint.me ("Website") understands any
  //             information that identifies the customer to whom such information
  //             pertains is of utmost importance. Company, therefore, has
  //             established the following privacy policy for its handling and
  //             protection of personal information.
  //           </div>
  //           {/* =============================================================== */}
  //           <div className="h-6"></div>
  //           <div
  //             className="
  //                           text-body-header font-bold
  //                           text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                       "
  //           >
  //             Personal Information Collection and Usage
  //           </div>
  //           <div className="h-4"></div>
  //           <div
  //             className="text-body-content-gray
  //                           text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                           leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                       "
  //           >
  //             <br />
  //             When you use, services provided on www.aappoint.me you will be
  //             asked to provide personal information such as name, contact
  //             address, e-mail, telephone number, Bank account number, etc. and including, but not limited to, users'
  //             behavior. Invitree generally collect this information for the
  //             following purposes:
  //             <br />
  //             <br />
  //             <ul className="list-disc">
  //               <li>
  //                 To facilitate your use of the Services (as defined in the
  //                 Terms of Use) and/or access to the website.
  //               </li>
  //               <li>
  //                 To facilitate your use of the Services by communicating via
  //                 phone call, email, or text message on the usage of the Service
  //                 of the Website or any activities from another third-parties’
  //                 website to acknowledge or answer the questions.
  //               </li>
  //               <li>
  //                 To deliver the sales promotion to the target market from
  //                 Personal Information Collection and Non-Personal Information
  //                 Collection through the platform, from the time to use the
  //                 platform and product categories or any of benefits from the
  //                 Website.
  //               </li>
  //               <li>
  //                 To deliver the Sales Offer to the target market from Personal
  //                 Information Collection.
  //               </li>
  //             </ul>
  //           </div>
  //           {/* =============================================================== */}
  //           <div className="h-6"></div>
  //           <div
  //             className="
  //                           text-body-header font-bold
  //                           text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                       "
  //           >
  //             Disclosure of Personal Information
  //           </div>
  //           <div className="h-4"></div>
  //           <div
  //             className="text-body-content-gray
  //                           text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                           leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                       "
  //           >
  //             <br />
  //             <ul className="list-disc">
  //               <li>
  //                 The Company will not disclose your personal identification
  //                 information to any third parties without your prior consent.
  //               </li>
  //               <li>
  //                 The Company may share only the personal information that is
  //                 relevant to the job, shop or the event for the Organizer or
  //                 the shop owner.
  //               </li>
  //               <li>
  //                 The Company may share our third-party service providers,
  //                 including but not limited to those who provide payment, or the e-mail service provider etc.
  //               </li>
  //               <li>
  //                 The Company will not reveal your personal information to any
  //                 third party unless required under law or by any government
  //                 enforcement agency or to protect the rights of property and
  //                 the safety of the Company employees, agents, and other web
  //                 site users etc.
  //               </li>
  //               <li>
  //                 In case of transfer of business mergers and acquisitions,
  //                 asset sales or loans. The Company may be required to disclose
  //                 some information to those involved in such transactions
  //                 accordingly.
  //               </li>
  //             </ul>
  //           </div>
  //           {/* =============================================================== */}
  //           <div className="h-6"></div>
  //           <div
  //             className="
  //                           text-body-header font-bold
  //                           text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                       "
  //           >
  //             Modification to Privacy and Disclaimer
  //           </div>
  //           <div className="h-4"></div>
  //           <div
  //             className="text-body-content-gray
  //                           text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                           leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                       "
  //           >
  //             <br />
  //             Company reserves the right to change this Privacy Policy and
  //             disclaimer at any time. The content presented on this page is
  //             considered the latest version. Should any modification to our
  //             Privacy Policy occur, we will post the updated version on the page
  //             so that you are always aware of how we safeguard your personal
  //             data.
  //           </div>
  //           {/* =============================================================== */}
  //           <div className="h-6"></div>
  //           <div
  //             className="
  //                           text-body-header font-bold
  //                           text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                       "
  //           >
  //             Security
  //           </div>
  //           <div className="h-4"></div>
  //           <div
  //             className="text-body-content-gray
  //                           text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                           leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                       "
  //           >
  //             <br />
  //             The Company has implemented the appropriate technical, physical
  //             security management and loss prevention using disproportionate
  //             access the data without permission to disclosure or adjustment
  //             change personal information.
  //             <br />
  //             <br />
  //             However, The Company reserves the liability protection of such
  //             security as there is not a single system or public network that
  //             can guarantee to be completely and thoroughly secured.
  //           </div>
  //           {/* =============================================================== */}
  //           <div className="h-6"></div>
  //           <div
  //             className="
  //                           text-body-header font-bold
  //                           text-size-15 max:text-size-40 2xl:text-size-35 xl:text-size-30 lg:text-size-25 md:text-size-20 sm:text-size-18
  //                       "
  //           >
  //             Contact Us
  //           </div>
  //           <div className="h-4"></div>
  //           <div
  //             className="text-body-content-gray
  //                           text-size-15 max:text-size-35 2xl:text-size-30 xl:text-size-25 lg:text-size-20 md:text-size-18 sm:text-size-16
  //                           leading-5 max:leading-10 2xl:leading-9 xl:leading-8 lg:leading-6 md:leading-5 sm:leading-5
  //                       "
  //           >
  //             <br />
  //             For further information, questions or comments regarding our
  //             privacy policy please contact us at :
  //             <br />
  //             Appointment Anywhere Co., Ltd. (Head office)
  //             <br />
  //             239/2 Lumpini 1 Building Room 52, 5th Floor, Ratchadamri Road
  //             Lumpini, Pathumwan, Bangkok 10330
  //             <br />
  //             Tel. 02-057 9135
  //           </div>
  //           {/* =============================================================== */}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}
